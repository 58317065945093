'use client';

import { PromoRotunda } from '@/features/promo/Rotunda';

import useInitChooseVoyage from '../ChooseVoyage/hooks/useInitChooseVoyage';
import PackageItineraryResults from './PackageItineraryResults';

const ItineraryResults = () => {
  useInitChooseVoyage();

  return (
    <>
      <PackageItineraryResults />
      <PromoRotunda />
    </>
  );
};

export default ItineraryResults;
