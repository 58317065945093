import React from 'react';

import Trending from '@publicImages/svg/trending.svg?static';

import { useUIResource } from '@/ducks/common/resources';

import styles from './PromotionTrending.module.scss';

const PromotionTrending = () => {
  const text = useUIResource('Trending.ItineraryResults');

  return (
    <div className={styles.root}>
      <Trending />
      <span className={styles.text}>{text}</span>
    </div>
  );
};

export default PromotionTrending;
