import Link from 'next/link';
import { useId, useMemo } from 'react';

import cn from 'classnames';

import SailorCardCalendar from '@publicImages/svg/sailor-card-calendar.svg?icon';

import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import AlmostSoldOut from '@/components/AlmostSoldOut';
import { Price } from '@/components/elements';
import UIResource from '@/components/UIResource';
import { CABIN_CATEGORY_CODES } from '@/constants/cabinCategoryCodes';
import { DEFAULT_CURRENCY_CODE } from '@/constants/settings';
import { selectCabins, selectPriceType, selectSailors } from '@/ducks/filters/selectors';
import { useIsPromoActive } from '@/features/promo/lib/useIsPromoActive';
import { getAmountPerItem, getAmountPerVoyage } from '@/helpers/data/mappers/Summary';
import getSymbolFromCurrenciesData from '@/helpers/util/currency/currencySymbols';
import { formatDate } from '@/helpers/util/dateUtil';
import { useIsMobile } from '@/hooks/useIsDevice';
import usePrecheckoutUrlWithParams from '@/hooks/usePrecheckoutPath';
import { FiltersPriceType } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';

import './SailingCard.scss';

type Props = {
  isBestPriceSailing?: boolean;
  onSailingClick?: (sailing: SailingFromPackages) => void;
  sailing: SailingFromPackages;
  strikethroughEnabled?: boolean;
};

const SailingCard = ({ isBestPriceSailing, onSailingClick, sailing, strikethroughEnabled }: Props) => {
  const cabins = useAppSelector(selectCabins) ?? 1;
  const priceType = useAppSelector(selectPriceType);
  const sailors = useAppSelector(selectSailors) ?? 2;
  const isPromoActive = useIsPromoActive('voyageCardMerchandising', sailing);
  const isMobile = useIsMobile();
  const currencyCode = sailing.startingPrice.currencyCode ?? DEFAULT_CURRENCY_CODE;

  const sailingPrice = useMemo(
    () =>
      priceType === FiltersPriceType.sailorPerNight
        ? getAmountPerVoyage(sailing.startingPrice, sailing.duration, { sailors })
        : getAmountPerItem(sailing.startingPrice, { cabins, priceType, sailors }),
    [cabins, sailing, priceType, sailors],
  );

  const leadCabin = sailing?.cabinCategoryCode;
  const isSuite = [CABIN_CATEGORY_CODES.MEGA_ROCKSTAR, CABIN_CATEGORY_CODES.ROCKSTAR_SUITES].includes(leadCabin);

  const classes = cn('SailingCard', {
    leadCabinType: isSuite,
    promoSailingCard: isPromoActive,
  });

  const urlPath = usePrecheckoutUrlWithParams(sailing);
  const suiteDescribeId = useId();

  const fullCurrencySymbol = getSymbolFromCurrenciesData(currencyCode, { fullSymbol: true });
  const shortCurrencySymbol = getSymbolFromCurrenciesData(currencyCode, { onlySymbol: true });
  const showCurrencySeparately = fullCurrencySymbol !== shortCurrencySymbol;

  return (
    <div className="SailorCardDetails">
      <Link
        aria-describedby={isSuite ? suiteDescribeId : undefined}
        className={classes}
        data-id={sailing?.id}
        href={urlPath}
        onClick={() => onSailingClick?.(sailing)}
        prefetch={false}
        role="link"
        tabIndex={0}
      >
        <div className="iconYear">
          <SailorCardCalendar /> {formatDate(sailing?.startDate, 'yyyy')}
          {isBestPriceSailing && (
            <span className="bestRateText">
              <UIResource id="Itinerary.BestRate.label" />
            </span>
          )}
        </div>
        <div className="startEndDate">
          <AlmostSoldOut
            classificationCodes={sailing.classificationCodes}
            className="almostSoldOut"
            messageId="AlmostSoldOut.SailingLevel.Emoji"
            withoutStyles
          />
          <span className="startEndDate-content">
            <span className="startDate">{formatDate(sailing?.startDate, 'E, MMM dd')}</span>
            <span className="endDate">
              {' - '}
              {formatDate(sailing?.endDate, 'E, MMM dd')}
            </span>
          </span>
        </div>
        <span className="priceFromLabel">
          <UIResource id="Cabins.Lead.Price.label.from" /> {showCurrencySeparately && `(${fullCurrencySymbol})`}
        </span>
        <span
          className={cn('price', {
            strikethroughEnabled,
          })}
        >
          <Price amount={sailingPrice} currencyCode={currencyCode} onlySymbol roundUp />
        </span>

        <AlmostSoldOut
          classificationCodes={sailing.classificationCodes}
          className="sr-only"
          messageId="AlmostSoldOut.lastRemainingCabins"
          withoutStyles
        />
      </Link>
      {isSuite && (
        <div className="leadSuite" id={suiteDescribeId}>
          {isMobile ? (
            <UIResource id="VoyageCard.onlySuitesLeft" />
          ) : (
            <UIResource id="VoyageCard.onlySuitesRemaining" />
          )}
        </div>
      )}
    </div>
  );
};

export const UI_RESOURCE_POOL = {
  desktopText: 'VoyageCard.onlySuitesRemaining',
  mobileText: 'VoyageCard.onlySuitesLeft',
};

export default SailingCard;
