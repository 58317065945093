import { useEffect, useRef } from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { payInFullPromoAppliedAction } from '@/actions';
import { selectSailors } from '@/ducks/filters/selectors';
import { selectFilteredPackages, selectFilteredSailings } from '@/ducks/pages/chooseVoyage/selectors';
import { getCelebrationsInfoSession } from '@/ducks/programs/celebrations/actions';
import { selectCelebrationsParams } from '@/ducks/programs/celebrations/selectors';
import { routes } from '@/ducks/routes';
import { resetTravelParty } from '@/ducks/travelParty/slice';
import { clearChoseCategoriesAndInvoice } from '@/ducks/voyageInfo/category/actions';
import { removePaymentPaymentOption, removePaymentReservationNumber } from '@/helpers/managers/payment';
import { removeSessionStorageValue, removeStoredValue } from '@/helpers/util';
import { trackSailorId } from '@/helpers/util/tracking';
import { type SailingFromPackages } from '@/infra/types/voyageInfo/sailing';
import { useAppDispatch, useAppSelector } from '@/store';
import tagmanager from '@/tagmanager';

const WAIT_TIME_WHILE_NAV_ROUTES_BUSY = 500;

const useInitChooseVoyage = () => {
  const dispatch = useAppDispatch();
  const appliedSailors = useAppSelector(selectSailors);
  const celebrationsParams = useAppSelector(selectCelebrationsParams);
  const filteredPackages = useAppSelector(selectFilteredPackages);
  const filteredSailings = useAppSelector(selectFilteredSailings);
  const isChangedPackagesEventSentRef = useRef<boolean>();

  useEffect(() => {
    // TODO: move to cookies
    const celebrationSessionData = getCelebrationsInfoSession();

    if (
      (celebrationSessionData && celebrationSessionData?.newReferral === false) ||
      celebrationsParams?.newReferral === false
    ) {
      const celebrationData =
        celebrationsParams?.voyageCode && celebrationsParams?.voyageId ? celebrationsParams : celebrationSessionData;
      const { packageCode, shipCode, voyageId } = celebrationData;

      setTimeout(() => {
        routes.planner.preCheckOut.go({
          keepSearch: true,
          packageCode,
          sailors: appliedSailors,
          shipCode,
          voyageId,
        });
      }, WAIT_TIME_WHILE_NAV_ROUTES_BUSY);
      return;
    }

    removeStoredValue('primarySailorError');

    removeSessionStorageValue('primarySailor');
    removePaymentReservationNumber();
    removePaymentPaymentOption();
    removeSessionStorageValue('add_flights');

    dispatch(resetTravelParty());
    dispatch(payInFullPromoAppliedAction(false));
    dispatch(clearChoseCategoriesAndInvoice());

    trackSailorId();
  }, []);

  const prevSailingsRef = useRef<SailingFromPackages[]>();
  useEffect(() => {
    const areSailingsChanged = !isEqual(filteredSailings, prevSailingsRef.current);
    const isFetchedEventSent = !isEmpty(filteredSailings) && !isChangedPackagesEventSentRef.current;
    if (areSailingsChanged) prevSailingsRef.current = filteredSailings;

    if (areSailingsChanged || isFetchedEventSent) {
      isChangedPackagesEventSentRef.current = true;
      tagmanager.observer.trackingEventsObserver.notify({
        data: {
          dispatch,
          packages: filteredPackages,
        },
        name: tagmanager.observer.TRACKING_EVENTS_FOR_OBSERVER.CHOOSE_SAILING_PAGE_LOADED,
      });
    }
  }, [filteredSailings]);
};

export default useInitChooseVoyage;
