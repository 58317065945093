import cn from 'classnames';

import { getIsAccessKeyStateValid, selectAccessKeySlice } from '@/ducks/accessKeys';
import { selectFilteredPackages, selectMainPackagesIsLoaded } from '@/ducks/pages/chooseVoyage/selectors';
import { selectIsFilteringLoading } from '@/ducks/pages/voyagePlanner/selectors';
import { useAppSelector } from '@/store';

import PackageCardSkeleton from './PackageCard/PackageCardSkeleton';
import PackageCardList from './PackageCardList/PackageCardList';

import './PackageCardDetails.scss';

const PackageCardDetails = () => {
  const isLoaded = useAppSelector(selectMainPackagesIsLoaded);
  const isLoading = useAppSelector(selectIsFilteringLoading);
  const packages = useAppSelector(selectFilteredPackages);
  const accessKeyDetails = useAppSelector(selectAccessKeySlice);
  const isAKValid = getIsAccessKeyStateValid(accessKeyDetails?.state);

  const cssClasses = cn('PackageCardChooseVoyage', {
    isAKWrapper: isAKValid,
    isSinglePackage: packages?.length === 1,
  });

  if (!isLoaded || isLoading) {
    return (
      <div className={cssClasses} id="PackageCardChooseVoyage">
        <PackageCardSkeleton key={1} />
        <PackageCardSkeleton key={2} />
        <PackageCardSkeleton key={3} />
      </div>
    );
  }

  return (
    <div className={cssClasses} id="PackageCardChooseVoyage">
      <PackageCardList packages={packages} />
    </div>
  );
};

export default PackageCardDetails;
