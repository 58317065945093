import { useCallback, useState } from 'react';

import cn from 'classnames';

import type { TSailingData } from '@/infra/types/voyageInfo/sailing';
import type { TOptional } from '@/types/common';

import Tick from '@/components/Icon/Tick';
import delay from '@/helpers/delay';
import useAutoFocus from '@/hooks/useAutoFocus';
import useClickOutside from '@/hooks/useClickOutside';

import ClipboardButton from './Clipboard';
import { ShareType } from './constants';
import EmailButton from './Email';
import FacebookButton, { ARIA_LABEL as FACEBOOK_ARIA_LABEL } from './Facebook';
import XTwitterButton from './XTwitter';

import './style.scss';

export const TEXT_COPY_STATUS = 'Link copied';

export type TSailingDataSlice = Partial<Pick<TSailingData, 'emailShareBody' | 'emailShareSubject' | 'socialShare'>>;

type TProps = React.ComponentProps<'div'> & {
  ariaAnnounceStatus?: string;
  autoFocus?: boolean;
  className?: string;
  isAltIcons?: TOptional<boolean>;
  isOmitClipboard?: TOptional<boolean>;
  isOmitCopyStatus?: TOptional<boolean>;
  isShortLink?: TOptional<boolean>;
  link?: string;
  onShare?: TOptional<(type: ShareType, url: string) => void>;
  sailingData?: TSailingDataSlice;
  title?: React.ReactNode;
};

const ShareLinkSet = ({
  ariaAnnounceStatus,
  autoFocus,
  className,
  isAltIcons,
  isOmitClipboard,
  isOmitCopyStatus,
  link,
  onShare,
  sailingData,
  title,
  ...restRootProps
}: TProps) => {
  const [isCopyDone, setIsCopyDone] = useState<boolean>();
  const copyDoneRef = useClickOutside<HTMLDivElement>(() => setIsCopyDone(false), !isCopyDone, true);
  const ref = useAutoFocus<HTMLDivElement>(autoFocus);
  const { emailShareBody, emailShareSubject, socialShare } = sailingData || {};

  const onButtonClick = useCallback(
    (type: ShareType) => {
      if (link) {
        // delay - for Safari
        delay(0).then(() => {
          if (type === ShareType.CLIPBOARD && !isOmitClipboard && !isOmitCopyStatus) {
            setIsCopyDone(true);
            delay(150).then(() => copyDoneRef.current?.focus());
          }
          onShare?.(type, link);
        });
      }
    },
    [isOmitClipboard, isOmitCopyStatus, link, onShare],
  );

  const onCopyStatusClick = useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();
    ref?.current?.querySelector<HTMLElement>('.ShareLinkSet-Clipboard button')?.focus();
    // delay - to preserve element parent for click outside
    delay(0).then(() => setIsCopyDone(false));
  }, []);

  const firstAriaLabel = ariaAnnounceStatus ? `${ariaAnnounceStatus}. ${FACEBOOK_ARIA_LABEL}` : undefined;

  return (
    <div {...restRootProps} className={cn('ShareLinkSet', className, { _alt: isAltIcons })} ref={ref}>
      {!!title && <h3 className="ShareLinkSet__title">{title}</h3>}
      <ul className="ShareLinkSet__items">
        <li>
          <FacebookButton aria-label={firstAriaLabel} isAltIcon={isAltIcons} link={link} onClick={onButtonClick} />
        </li>
        <li>
          <XTwitterButton isAltIcon={isAltIcons} link={link} onClick={onButtonClick} template={socialShare} />
        </li>
        <li>
          <EmailButton
            isAltIcon={isAltIcons}
            link={link}
            onClick={onButtonClick}
            templateBody={emailShareBody}
            templateSubject={emailShareSubject}
          />
        </li>
        {!isOmitClipboard && (
          <li>
            <ClipboardButton isAltIcon={isAltIcons} link={link} onClick={onButtonClick} />
          </li>
        )}
      </ul>

      {isCopyDone && (
        <div
          className="ShareLinkSet__copy-status"
          onClick={onCopyStatusClick}
          onKeyDown={onCopyStatusClick}
          ref={copyDoneRef}
          role="button"
          tabIndex={0}
        >
          {TEXT_COPY_STATUS}
          <Tick />
        </div>
      )}
    </div>
  );
};

export default ShareLinkSet;
