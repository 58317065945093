import { useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import type { Package } from '@/infra/types/voyageInfo/package';

import CabinPrice from '@/components/CabinCard/CabinPrice';
import UIResource from '@/components/UIResource';
import UIResourceHtml from '@/components/UIResource/Html';
import { UpliftWithSkeleton } from '@/components/Uplift';
import { CABIN_CATEGORY_CODES } from '@/constants/cabinCategoryCodes';
import { useUIResource } from '@/ducks/common/resources';
import { selectCabins, selectPriceType, selectSailors } from '@/ducks/filters/selectors';
import { selectUpliftIsReady } from '@/features/uplift/selectors';
import { getAmountPerItem, getAmountPerVoyage } from '@/helpers/data/mappers/Summary';
import { FiltersPriceType } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';

import './PackagePrice.scss';
type Props = {
  customUpliftIcon?: React.ReactNode;
  noUplift?: boolean;
  packageData?: Pick<Package, 'bestPriceSailing' | 'duration' | 'sailingList' | 'startingPrice'>;
  strikethroughValue?: number;
};

const priceFootnote = {
  perCabin: 'Package.average.perCabin.text',
  perSailor: 'Package.average.perSailor.text',
  SailorPerNight: 'Package.average.perSailorPerNight.text',
} as const;

const PackagePrice = ({ customUpliftIcon, noUplift, packageData, strikethroughValue }: Props) => {
  const cabins = useAppSelector(selectCabins);
  const priceType = useAppSelector(selectPriceType) as keyof typeof priceFootnote;
  const sailorCount = useAppSelector(selectSailors);

  const isUpliftReady = useAppSelector(selectUpliftIsReady);
  const { startingPrice } = packageData?.bestPriceSailing ?? {};

  const priceFootnoteText = useUIResource(priceFootnote[priceType]);

  const priceForUpliftLabel = useMemo(() => {
    const price =
      priceType === FiltersPriceType.sailorPerNight
        ? getAmountPerVoyage(startingPrice, packageData?.duration, { sailors: sailorCount })
        : getAmountPerItem(startingPrice, { cabins, priceType: priceType as FiltersPriceType, sailors: sailorCount });
    return price ? `From ${price} ${startingPrice?.currencyCode ?? 'USD'} ${priceFootnoteText || ''}` : '';
  }, [priceType, packageData, sailorCount, priceFootnoteText, startingPrice]);

  const sailingPrice = getAmountPerItem(startingPrice, {
    cabins,
    priceType: priceType as FiltersPriceType,
    sailors: sailorCount,
  });
  // TODO: is it safe that we get the last in the list?
  const sailing = packageData?.sailingList?.at(-1);
  const withUplift = !noUplift && isUpliftReady && sailing && !isEmpty(sailing.ports) && !isEmpty(sailing.startDate);
  const upliftIcon = withUplift && (
    <span className="packageCardupliftTooltip" id="upliftToolTipPackageCard">
      <span className="packageCardupliftTooltipIcon">i</span>
    </span>
  );
  const leastPricedCabin = [...(packageData?.sailingList ?? [])]
    .sort((a, b) => a?.startingPrice?.amount - b?.startingPrice?.amount)
    ?.at(0);
  const leadCabin = leastPricedCabin?.cabinCategoryName;
  const leadCabinCode = leastPricedCabin?.cabinCategoryCode;
  const upliftSkeletonProps = useMemo(
    () => ({
      height: '18px',
      width: '150px',
    }),
    [],
  );
  const isSuite =
    leadCabinCode && [CABIN_CATEGORY_CODES.MEGA_ROCKSTAR, CABIN_CATEGORY_CODES.ROCKSTAR_SUITES].includes(leadCabinCode);

  return (
    <div className="PackagePrice">
      <div className="fromLabel">
        {leadCabin ? (
          <UIResourceHtml
            id={isSuite ? 'PackageCard.leadInPrice.suite' : 'PackageCard.leadInPrice.cabin'}
            values={{ Metacategory: leadCabin }}
          />
        ) : (
          <UIResource id="Cabins.Lead.Price.label.from" />
        )}
      </div>
      <div className="priceTotal">
        <CabinPrice
          abTestPriceType={priceType}
          currencyCode={startingPrice?.currencyCode ?? 'USD'}
          duration={packageData?.duration}
          isForceLineBreak={false}
          sailors={sailorCount}
          startingPrice={startingPrice}
          strikethroughValue={strikethroughValue}
        />
      </div>
      {priceType && <div className="priceFootNote">{priceFootnoteText}</div>}
      <div className="PackagePrice__upliftContainer">
        <UpliftWithSkeleton
          ariaLabel={priceForUpliftLabel}
          currencyCode={startingPrice?.currencyCode ?? 'USD'}
          customIcon={customUpliftIcon ? upliftIcon : ''}
          isHorizontal
          isShowUplift={withUplift}
          price={sailingPrice}
          priceType={priceType}
          sailing={sailing}
          showOrFromLabel
          skeletonProps={upliftSkeletonProps}
        />
      </div>
    </div>
  );
};

export default PackagePrice;
