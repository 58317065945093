'use client';

import AccessKeyBanner from '@/components/AccessKeyBanner';
import AdvancedSelection from '@/components/ChooseVoyage/AdvancedSelection';
import PackageCardDetails from '@/components/ChooseVoyage/PackageCardDetails';
import MNVVOfferBanner from '@/components/MNVVOfferBanner';
import PromoBannerPlace from '@/components/PromoBanner/Place';
import UIResource from '@/components/UIResource';
import WarningBox, { WarningTarget } from '@/components/WarningBox';
import WhatsIncluded from '@/components/WhatsIncluded';
import { type TUIResourceId } from '@/ducks/common/resources';
import { selectPriceType } from '@/ducks/filters/selectors';
import { PromoBannerPlaceId } from '@/features/promoBanners/types';
import useClearWarningOnAway from '@/features/warnings/hooks/useClearWarningOnAway';
import withSideqikScriptForBookingFlow from '@/helpers/hoc/utility/withSideqikScriptForBookingFlow';
import { useGetIsTaxIncluded } from '@/helpers/pricing/lib/tax';
import { useAppSelector } from '@/store';
import { WHATS_INCLUDED_KEYS } from '@/types/vwo';

import '../PackageResults.scss';

const PackageItineraryResults = () => {
  const priceType = useAppSelector(selectPriceType);
  const isTaxIncluded = useGetIsTaxIncluded();

  useClearWarningOnAway([WarningTarget.chooseVoyage, WarningTarget.preCheckoutTop]);

  return (
    <div className="PackageItineraryResults__body">
      <WhatsIncluded vwoFeatureVariableKey={WHATS_INCLUDED_KEYS.CHOOSE_VOYAGE_PAGE} />
      <AdvancedSelection />
      <PromoBannerPlace placeId={PromoBannerPlaceId.itineraryResultsB} />
      <WarningBox className="PackageItineraryResults__warningBox" scrollToBox target={WarningTarget.chooseVoyage} />
      <AccessKeyBanner />
      <MNVVOfferBanner />
      <PackageCardDetails />

      <div className="PackageItineraryResults__footer" suppressHydrationWarning>
        <UIResource
          id={`NewChooseVoyage.TaxesFees${isTaxIncluded ? 'Included' : 'Excluded'}.${priceType}` as TUIResourceId}
        />
      </div>
    </div>
  );
};

export default withSideqikScriptForBookingFlow(PackageItineraryResults, {});
