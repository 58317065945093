import SortIcon from '@publicImages/svg/sort.svg?icon';

import type { FiltersSortType } from '@/infra/types/common/filters';

import Select from '@/components/Select';
import { selectSortType } from '@/ducks/filters/selectors';
import { setFilter } from '@/ducks/filters/setters';
import { selectDefaultFilterSortType } from '@/ducks/filtersOptions';
import { useAppSelector } from '@/store';

import { useSortOptions } from './hooks';

import styles from './SortRefinementForPackages.module.scss';

const SortRefinementForPackages = () => {
  const sortType = useAppSelector(selectSortType);
  const defaultSortType = useAppSelector(selectDefaultFilterSortType);

  const onApply = (value: FiltersSortType) => {
    setFilter.sortType(value);
  };

  const sortOptions = useSortOptions();

  return (
    <form className={styles.root}>
      <span aria-hidden="true" className={styles.title}>
        Sort:
      </span>
      <Select
        aria-label="Sort options"
        className={styles.select}
        defaultValue={defaultSortType}
        icon={<SortIcon />}
        onValueChange={onApply}
        options={sortOptions}
        value={sortType}
      />
    </form>
  );
};

export default SortRefinementForPackages;
